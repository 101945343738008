import logoImage from './assets/media/logo.1adebf2b8d531a9b526bf5e93c68c2eb.svg';
import ContactImage from './assets/img/icon-contact.png'

export function Contact(){
    return `
    <!DOCTYPE html>
<html lang="en">
  <head>
  <meta charset="utf-8" />

  <meta content="IE=edge,chrome=1" http-equiv="X-UA-Compatible" />

  <meta
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
    name="viewport"
  />

  <title>Contact | Atlas Template</title>

  <meta property="og:title" content="Contact | Atlas Template" />

  <meta property="og:locale" content="en_US" />

  <link
    rel="canonical"
    href="https://atlas.tailwindmade.com/contact"
  />

  <meta
    property="og:url"
    content="https://atlas.tailwindmade.com/contact"
  />

  <meta
    name="description"
    content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  />

  <link rel="icon" type="image/png" href="/assets/img/favicon.png" />

  <meta property="og:site_name" content="Atlas Template" />

  <meta property="og:image" content="https://atlas.tailwindmade.com/assets/img/social.jpg" />

  <meta name="twitter:card" content="summary_large_image" />

  <meta name="twitter:site" content="@tailwindmade" />

  <link
    crossorigin="crossorigin"
    href="https://fonts.gstatic.com"
    rel="preconnect"
  />

  <link
    as="style"
    href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
    rel="preload"
  />

  <link
    href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
    rel="stylesheet"
  />

  <link
    rel="stylesheet"
    href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css"
  />

  <link
    crossorigin="anonymous"
    href="/assets/styles/main.min.css"
    media="screen"
    rel="stylesheet"
  />

  <script src="//cdnjs.cloudflare.com/ajax/libs/highlight.js/10.5.0/highlight.min.js"></script>

  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/10.5.0/styles/atom-one-dark.min.css"
  />

  <script>
    hljs.initHighlightingOnLoad();
  </script>

  <!--Alpine Js V3-->
  <script src="https://unpkg.com/alpinejs@3.x.x/dist/cdn.min.js" defer></script>
</head>


  <body
    x-data="global()"
    x-init="themeInit()"
    class="dark:bg-primary"
  >
    
    <div id="main">
      <div class="container mx-auto">
  <div class="flex items-center justify-between py-6 lg:py-10">
    <a href="/" class="flex items-center">
      <span href="/" class="mr-2">
        <img src=${logoImage} alt="logo" />
      </span>
      <p
        class="hidden font-body text-2xl font-bold text-primary dark:text-white lg:block"
      >
        Golam Rabbani
      </p>
    </a>
    <div class="flex items-center lg:hidden">
      <i
        class="bx mr-8 cursor-pointer text-3xl text-primary dark:text-white"
        @click="themeSwitch()"
      ></i>

      <svg
        width="24"
        height="15"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current text-primary dark:text-white"
      >
        <g fill-rule="evenodd">
          <rect width="24" height="3" rx="1.5" />
          <rect x="8" y="6" width="16" height="3" rx="1.5" />
          <rect x="4" y="12" width="20" height="3" rx="1.5" />
        </g>
      </svg>
    </div>
    <div class="hidden lg:block">
      <ul class="flex items-center">
        
        <li class="group relative mr-6 mb-1">
          <div
            class="absolute left-0 bottom-0 z-20 h-0 w-full opacity-75 transition-all group-hover:h-2 group-hover:bg-yellow"
          ></div>
          <a
            href="/"
            class="relative z-30 block px-2 font-body text-lg font-medium text-primary transition-colors group-hover:text-green dark:text-white dark:group-hover:text-secondary"
            >Intro</a
          >
        </li>
        
        <li class="group relative mr-6 mb-1">
          <div
            class="absolute left-0 bottom-0 z-20 h-0 w-full opacity-75 transition-all group-hover:h-2 group-hover:bg-yellow"
          ></div>
          <a
            href="/blog"
            class="relative z-30 block px-2 font-body text-lg font-medium text-primary transition-colors group-hover:text-green dark:text-white dark:group-hover:text-secondary"
            >Blog</a
          >
        </li>
        
        <li class="group relative mr-6 mb-1">
        <div
          class="absolute left-0 bottom-0 z-20 h-0 w-full opacity-75 transition-all group-hover:h-2 group-hover:bg-yellow"
        ></div>
        <a
          href="/resume"
          class="relative z-30 block px-2 font-body text-lg font-medium text-primary transition-colors group-hover:text-green dark:text-white dark:group-hover:text-secondary"
          >Resume</a
        >
        </li>
        
        <li class="group relative mr-6 mb-1">
          <div
            class="absolute left-0 bottom-0 z-20 h-0 w-full opacity-75 transition-all group-hover:h-2 group-hover:bg-yellow"
          ></div>
          <a
            href="/contact"
            class="relative z-30 block px-2 font-body text-lg font-medium text-primary transition-colors group-hover:text-green dark:text-white dark:group-hover:text-secondary"
            >Contact</a
          >
        </li>
        
        <li>
          <i
            class="bx cursor-pointer text-3xl text-primary dark:text-white"
            @click="themeSwitch()"
          ></i>
        </li>
      </ul>
    </div>
  </div>
</div>

<div
  class="pointer-events-none fixed inset-0 z-50 flex bg-black bg-opacity-80 opacity-0 transition-opacity lg:hidden"
>
  <div class="ml-auto w-2/3 bg-green p-4 md:w-1/3">
    <i
      class="bx bx-x absolute top-0 right-0 mt-4 mr-4 text-4xl text-white"
    ></i>
    <ul class="mt-8 flex flex-col">
      
      <li class="">
        <a
          href="/"
          class="mb-3 block px-2 font-body text-lg font-medium text-white"
          >Intro</a
        >
      </li>
      
      <li class="">
        <a
          href="/blog"
          class="mb-3 block px-2 font-body text-lg font-medium text-white"
          >Blog</a
        >
      </li>
      
      <li class="group relative mr-6 mb-1">
      <div
        class="absolute left-0 bottom-0 z-20 h-0 w-full opacity-75 transition-all group-hover:h-2 group-hover:bg-yellow"
      ></div>
      <a
        href="/resume"
        class="relative z-30 block px-2 font-body text-lg font-medium text-primary transition-colors group-hover:text-green dark:text-white dark:group-hover:text-secondary"
        >Resume</a
      >
      </li>

      <li class="group relative mr-6 mb-1">
      <div
        class="absolute left-0 bottom-0 z-20 h-0 w-full opacity-75 transition-all group-hover:h-2 group-hover:bg-yellow"
      ></div>
      <a
        href="/bro-bot"
        class="relative z-30 block px-2 font-body text-lg font-medium text-primary transition-colors group-hover:text-green dark:text-white dark:group-hover:text-secondary"
        >Bro Bot</a
      >
      </li>
      
      <li class="">
        <a
          href="/contact"
          class="mb-3 block px-2 font-body text-lg font-medium text-white"
          >Contact</a
        >
      </li>
      
    </ul>
  </div>
</div>


      <div><div class="container mx-auto">
  <div class="py-16 lg:py-20">
    <div>
      <img src=${ContactImage} alt="icon envelope" />
    </div>
    <h1
      class="pt-5 font-body text-4xl font-semibold text-primary dark:text-white md:text-5xl lg:text-6xl"
    >
      Contact
    </h1>
    <div class="pr-2 pt-3 sm:pt-0">
      <p class="font-body text-xl font-light text-primary dark:text-white">
        Got a question or proposal, or just want to say hello? Go ahead. You know what to do.
      </p>
    </div>
    <form class="pt-16">
      <div class="flex flex-col sm:flex-row">
        <div class="w-full sm:mr-3 sm:w-1/2">
          <label
            class="block pb-3 font-body font-medium text-primary dark:text-white"
            >Your Name</label
          >
          <input
            type="text"
            id="name"
            placeholder="What should I call you?"
            class="w-full border border-primary bg-grey-lightest px-5 py-4 font-body font-light text-primary placeholder-primary transition-colors focus:border-secondary focus:outline-none focus:ring-2 focus:ring-secondary dark:text-white"
          />
        </div>
        <div class="w-full pt-6 sm:ml-3 sm:w-1/2 sm:pt-0">
          <label
            class="block pb-3 font-body font-medium text-primary dark:text-white"
            >Email Address</label
          >
          <input
            type="email"
            id="email"
            placeholder="Drop that email here…"
            class="w-full border border-primary bg-grey-lightest px-5 py-4 font-body font-light text-primary placeholder-primary transition-colors focus:border-secondary focus:outline-none focus:ring-2 focus:ring-secondary dark:text-white"
          />
        </div>
      </div>
      <div class="w-full pt-6 sm:pt-10">
        <label
          class="block pb-3 font-body font-medium text-primary dark:text-white"
          >Email Address</label
        >
        <textarea
          id="message"
          cols="30"
          rows="9"
          placeholder="Tell me all the things that you think I need to hear…"
          class="w-full border border-primary bg-grey-lightest px-5 py-4 font-body font-light text-primary placeholder-primary transition-colors focus:border-secondary focus:outline-none focus:ring-2 focus:ring-secondary dark:text-white"
        ></textarea>
      </div>
      <button
        class="mt-10 mb-12 block bg-secondary px-10 py-4 text-center font-body text-xl font-semibold text-white transition-colors hover:bg-green sm:inline-block sm:text-left sm:text-2xl"
      >
        Send Message
      </button>
    </form>
  </div>
</div>
</div>

      <div class="container mx-auto">
  <div
    class="flex flex-col items-center justify-between border-t border-grey-lighter py-10 sm:flex-row sm:py-12"
  >
    <div class="mr-auto flex flex-col items-center sm:flex-row">
      <a href="/" class="mr-auto sm:mr-6">
        <img src=${logoImage} alt="logo" />
      </a>
      <p class="pt-5 font-body font-light text-primary dark:text-white sm:pt-0">
        ©2023 Golam Rabbani.
      </p>
    </div>
    <div class="mr-auto flex items-center pt-5 sm:mr-0 sm:pt-0">
      
      <a href="https://github.com/rabbani204 " target="_blank">
        <i
          class="text-4xl text-primary dark:text-white pl-5 hover:text-secondary dark:hover:text-secondary transition-colors bx bxl-github"
        ></i>
      </a>
      
      <a href="https://codepen.io/rabbani204 " target="_blank">
        <i
          class="text-4xl text-primary dark:text-white pl-5 hover:text-secondary dark:hover:text-secondary transition-colors bx bxl-codepen"
        ></i>
      </a>
      
      <a href="https://www.linkedin.com/in/rabbani204/" target="_blank">
        <i
          class="text-4xl text-primary dark:text-white pl-5 hover:text-secondary dark:hover:text-secondary transition-colors bx bxl-linkedin"
        ></i>
      </a>
      
    </div>
  </div>
</div>

    </div>

    <script src="/assets/js/main.js"></script>

    
  </body>
</html>

    
    `
}